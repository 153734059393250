import React from "react";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";

import "./pagination.css";
const PaginationIndex = ({
  totalProducts,
  productsPerPage,
  currentPage,
  handlePageChange,
  handleNextPage,
  handlePreviousPage,
  gotoFirstPage,
}) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="row pgntn_par">
      <div className="d-flex align-items-center  flex-wrap justify-content-center">
        <div
          className="d-flex align-items-center me-3 "
          role="button"
         
        >
          <MdOutlineKeyboardDoubleArrowLeft style={{ color: "#A8A8A8" }} />
          <button
            className="page-1 ps-2 border-0 bg-transparent"
            onClick={gotoFirstPage}
            disabled={currentPage === 0}
          >
            page 1
          </button>
        </div>
        <div className="page_number">
          <a className="prev" onClick={handlePreviousPage}>
            <FaChevronLeft style={{ color: "#666666" }} />
            <span className="pre ps-2">Previous</span>
          </a>
          <div>
            <div className="page_count">
              {/* Page {currentPage} of {totalPages} */}
              Page {currentPage + 1} of {totalPages}
            </div>
          </div>
          <a className="next" onClick={handleNextPage}>
            <span className="next pe-2">Next</span>
            <FaChevronRight style={{ color: "#666666" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaginationIndex;
