import React, { useState, useEffect } from "react";
import "../css/productCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import noImage from "../images/1.jpg";
import { addToWishList, getProductImages } from "../services/apiServices";
import toast from "react-hot-toast";
import { Skeleton } from "@mui/material";
import { VIEW_PRODUCT_DETAILS } from "../shared/slugs";
import useFindMenuID from "../hooks/useFindSlug";
import useReducerHook from "../hooks/useReducerHook";

const Card = ({
  product,
  height = "300px",
  isProgress,
  progressValue,
  rating = false,
  isLoading,
}) => {
  const {pageMenus}=useReducerHook();
  const [assetImages, setAssetImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hoverInterval, setHoverInterval] = useState(null);
  const [isHovered, setIsHovered] = useState(false); // To track hover state
  const navigate = useNavigate();
  const productId = product?.product_id;
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const token = localStorage.getItem("token");
  let viewproductID = useFindMenuID(pageMenus?.menuData, VIEW_PRODUCT_DETAILS);
  // useEffect(() => {
  //   productImageAsets(productId);
  // }, []);

  // Handle mouse enter to start image sliding
  const handleMouseEnter = (id) => {
   if(id){
    productImageAsets(id);

   }
    setIsHovered(true);
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === assetImages?.length - 1 ? 0 : prevIndex + 1
      );
    }, 1000); // Change image every 1 second
    setHoverInterval(interval);
  };

  // Handle mouse leave to stop the sliding and reset to the first image
  const handleMouseLeave = () => {
    clearInterval(hoverInterval);
    setHoverInterval(null);
    setIsHovered(false);
    setCurrentImageIndex(0); 
  };

  // Navigate to the single product page
  const handleClick = (id) => {
    navigate(`/${viewproductID?.page_slug}`, {
      state: {
        page_id: viewproductID?.page_id,
        productId: parseInt(product?.product_id),
      },
    });
    window.scrollTo({ top: 0, behavior: "smooth" });

  };

  // Add to wishlist handler
  const handleAddtowishlist = async () => {
    const payload = {
      product_id: +product.product_id,
    };
    const response = await addToWishList(token, payload);
    if (response?.status === "success") {
      toast.success(response?.message);
    } else if (response === 403) {
      toast.error("Product already exists in the wishlist");
    } else {
      toast.error(response?.message);
    }
  };

  // Fetch product asset images from API
  const productImageAsets = async (productId) => {
    try {
      const response = await getProductImages(productId);
      if (response.status_code === 200) {
        const imagePaths = response?.data?.map((item) => item?.image_path);
        setAssetImages(imagePaths);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div
      className="card card-hover"
      style={{ border: "none" }}
      onMouseEnter={() => handleMouseEnter(product?.product_id)}
      onMouseLeave={handleMouseLeave}
    >
      {isLoading ? (
        <Skeleton variant="rectangular" width={225} height={300} />
      ) : (
        <div className="card-image">
          <img
            src={
              // AppUrl + product?.thumnail_image
              isHovered && assetImages?.length > 0
                ? AppUrl + assetImages[currentImageIndex]
                : product?.thumnail_image?.endsWith("undefined") ||
                  product?.thumnail_image === ""
                ? noImage
                : AppUrl + product?.thumnail_image
            }
            alt={product?.product_name}
            style={{ height: height }}
            onClick={()=>handleClick(product?.product_id)}
          />
          <div className="image-indicators">
            {isHovered && assetImages?.length > 0 && (
              <span
                className={`indicator ${
                  currentImageIndex === 0 ? "active" : ""
                }`}
              ></span>
            )}
          </div>
          <div className="quick-view" onClick={() => handleAddtowishlist()}>
            <FontAwesomeIcon icon={faHeart} style={{ marginRight: "10px" }} />
            WISHLIST
          </div>
        </div>
      )}
      <div
        className="card-details"
        style={{ cursor: "pointer" }}
        onClick={() => handleClick(product?.product_id)}
      >
        {isLoading ? (
          <Skeleton variant="text" height={20} />
        ) : (
          <h3 className="card-brand">{product?.brand_name}</h3>
        )}
        <p className="card-name">
          {product?.product_name?.length > 30
            ? `${product?.product_name.substring(0, 30)}...`
            : product?.product_name}
        </p>
        {isLoading ? (
          <Skeleton variant="text" height={30} />
        ) : (
          <div className="card-pricing">
            {product?.discount_price !== 0 ? <>
              <span className="card-discount-price">
              Rs.{product?.discount_price}
            </span>
            <span className="card-original-price">
              {product?.original_price}
            </span>
            </>:  <span className="card-discount-price">
              Rs.{product?.original_price}
            </span>}
           
            {product?.discount_percentage !== 0 && (
              <span className="card-discount-percentage">
                {product?.discount_percentage}%
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
