import React from "react";
import useNavigateShop from "../../../hooks/useNavigateShop";
import useReducerHook from "../../../hooks/useReducerHook";
import { SHOP } from "../../../shared/slugs";
import useFindMenuID from "../../../hooks/useFindSlug";

const Section1 = ({ sections }) => {
  const adsData = sections?.find((item) => item?.key_name === "ADS")?.ads_data;
  const Appurl = process.env.REACT_APP_IMAGE_URL;
  const { navigateShop } = useNavigateShop();
  const { pageMenus } = useReducerHook();
  let shopPage = useFindMenuID(pageMenus?.menuData, SHOP);
  return (
    <div className="container-fluid p-0 my-3">
      <img
        src={Appurl + adsData?.[0].image_path}
        alt=""
        className="img-fluid d-view"
        onClick={() => navigateShop(adsData?.[0]?.link_one, shopPage)}
      />
      <img
        src={Appurl + adsData?.[0]?.responsive_image_path}
        alt=""
        className="m-view img-fluid"
      />
    </div>
  );
};

export default Section1;
