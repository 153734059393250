import React, { useState } from "react";
import { changePassword } from "../../../services/changePasswordServices";
import { useNavigate } from "react-router-dom";
import useFindMenuID from "../../../hooks/useFindSlug";
import useNavigateMenuId from "../../../hooks/useMenuId";
import useReducerHook from "../../../hooks/useReducerHook";
import { LOGIN } from "../../../shared/slugs";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token"); 
  const {navigatePageId}= useNavigateMenuId();
  const { pageMenus } = useReducerHook();

  let loginPage = useFindMenuID(pageMenus?.menuData, LOGIN);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
      try {
        const response = await changePassword(newPassword, token);
        if (response.status_code === 200) {
          localStorage.removeItem("token");
          navigatePageId(loginPage)
        }
      } catch (error) {}
    }
  };

  return (
    <div className="container-fluid card p-4 h-75">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <h3 className="text-center mb-4">Change Password</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className="text-danger mb-3">{error}</div>}
            <button type="submit" className="btn btn-primary w-100">
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
