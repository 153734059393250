import React from "react";
import { APP_URL } from "../../../shared/constants";
import useNavigateShop from "../../../hooks/useNavigateShop";
import useReducerHook from "../../../hooks/useReducerHook";
import useFindMenuID from "../../../hooks/useFindSlug";
import { SHOP } from "../../../shared/slugs";

const ExtraSection4 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();

  function consolidatedData() {
    let res = {
      heading: "",
      ads: [],
    };
    let txt = sections?.find((x) => x?.key_name === "TITLE_1")?.key_value;
    res.heading = txt;
    let data = sections?.find((x) => x?.key_name === "ADS")?.ads_data;

    res.ads = data;
    return res;
  }
  const { pageMenus } = useReducerHook();
  let shopPage = useFindMenuID(pageMenus?.menuData, SHOP);
  return (
    <div className="container-fluid my-3">
      <h4 className="text-center pt-2">{consolidatedData()?.heading}</h4>

      <div className="row">
        {consolidatedData()?.ads?.length > 0 &&
          consolidatedData()?.ads?.map((item) => (
            <div
              className="col-6 col-sm-4 col-md-2 col-lg-3 mt-4"
              key={item?.id}
            >
              <div
                className=""
                onClick={() => navigateShop(item?.link_one, shopPage)}
              >
                <img
                  src={APP_URL + item?.image_path}
                  alt={item?.key_name}
                  className="img-fluid d-view"
                />
                <img
                  src={APP_URL + item?.responsive_image_path}
                  alt={item?.key_name}
                  className="img-fluid m-view"
                />
                <h4 className="text-center pt-2">{item?.title_one}</h4>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExtraSection4;
