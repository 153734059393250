import React, { useRef, useState } from 'react'
import { Alert, Button } from '@mui/material';
import { signup } from '../../services/apiServices'
import toast from 'react-hot-toast';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from 'formik';
import * as Yup from 'yup'; 

function Register({ handleOpenOtpModal, setFormSwitch, }) {
    const recaptchaRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const [apiError, setApiError] = useState("");

    const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

    // Form validation schema using Yup (optional)
    const validationSchema = Yup.object({
        userName: Yup.string().min(3, 'Username must be at least 3 characters and contain only letters.').required('Username is required'),
        email: Yup.string().email('Please enter a valid email address').required('Email is required'),
        mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits').required('Mobile number is required'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password is required'),
        terms_agree: Yup.boolean().oneOf([true], 'You must accept the terms and conditions').required(),
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            userName: '',
            email: '',
            mobile: '',
            password: '',
            confirmPassword: '',
            terms_agree: false,
        },
        validationSchema,
        onSubmit: async (values) => {
            const recaptchaValue = recaptchaRef.current.getValue();
            if (recaptchaValue) {
                try {
                    const payload = {
                        "full_name": values.userName,
                        "email": values.email,
                        "phone_no": +values.mobile,
                        "password": values.password,
                        "confirmPassword": values.confirmPassword,
                        "terms_agree": values.terms_agree ? 1 : 0,
                        captcha,
                    };

                    const response = await signup(payload);
                    if (response.status_code === 200) {
                        localStorage.setItem("email", values.email);
                        toast.success(response.message);
                        handleOpenOtpModal();
                    }
                } catch (error) {
                    recaptchaRef.current.reset();
                    setCaptcha("");
                    setApiError(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong');
                }
            } else {
                toast.error("Please verify captcha");
            }
        },
    });

    return (
        <div className="register-form-container">
            <form onSubmit={formik.handleSubmit}>
                <input
                    className="input"
                    type="text"
                    placeholder="Username"
                    {...formik.getFieldProps('userName')}
                />
                {formik.touched.userName && formik.errors.userName && <p className="error">{formik.errors.userName}</p>}

                <input
                    className="input"
                    type="email"
                    placeholder="Email"
                    {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && <p className="error">{formik.errors.email}</p>}

                <input
                    className="input"
                    type="text"
                    onKeyPress={(event) => !/[0-9]/i.test(event.key) && event.preventDefault()}
                    placeholder="Mobile Number"
                    {...formik.getFieldProps('mobile')}
                    maxLength={10}
                    minLength={10}
                />
                {formik.touched.mobile && formik.errors.mobile && <p className="error">{formik.errors.mobile}</p>}

                <input
                    className="input"
                    type="password"
                    placeholder="Password"
                    {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && <p className="error">{formik.errors.password}</p>}

                <div className="login-pw-container">
                    <input
                        className="input"
                        type={showPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        autoComplete="off"
                        {...formik.getFieldProps('confirmPassword')}
                    />
                    <div onClick={() => setShowPassword((s) => !s)} style={{ cursor: "pointer" }}>
                        {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </div>
                </div>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && <p className="error">{formik.errors.confirmPassword}</p>}

                <ReCAPTCHA
                    sitekey={siteKey}
                    ref={recaptchaRef}
                    onChange={(value) => setCaptcha(value)}
                />

                <div className="reg-checkbox">
                    <input
                        type="checkbox"
                        checked={formik.values.terms_agree}
                        {...formik.getFieldProps('terms_agree')}
                    />
                    <p>By continuing, I agree to the <span>Privacy Policy</span> &{' '}<span>Terms of Use.</span></p>
                </div>
                {apiError && <Alert severity="error" sx={{mb:2}}>{apiError}</Alert>}

                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#FF3F6C',
                        width: '100%',
                        borderRadius: '0px',
                        fontWeight: '600'
                    }}
                    type="submit"
                    disabled={!formik.values.terms_agree || formik.isSubmitting}
                >
                    Register
                </Button>
            </form>

            <p className="already-have-acc">
                Have an account already ? {' '}
                <span onClick={() => setFormSwitch('login')}>Login.</span>
            </p>
        </div>
    );
}

export default Register;
