import { createContext, useContext, useEffect, useState } from "react";
import {
  addToCartApi,
  addToWishList,
  getAllProductsAPICall,
  getAssetsForProduct,
  getImageVarients,
  getRangeForPagination,
  getSingleProduct,
} from "../../../services/ProductService";
import {
  getBrandOptionsAPICall,
  getCategoriesOptionsAPICall,
} from "../../../services/CategoryService";
import toast from "react-hot-toast";
import { fetchUserData } from "../../../Redux/Slice/userDeatails";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setSearchData } from "../../../Redux/Slice/SearchData";

const ShopContext = createContext(null);

function ShopProvider({ children }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { state } = useLocation();
  const [isQuickView, setIsQuickView] = useState(false);
  const [gridView, setGridView] = useState("3");
  const [priceRangeData, setPriceRangeData] = useState({});
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [productCount, setProductCount] = useState(null);

  const [orderFilter, setOrderFilter] = useState("asc");
  const [productDetails, setProductDetails] = useState({});
  const [assetsDetails, setAssetsDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const ITEM_PER_PAGE = 20;
  const [productVarientsData, setProductVarients] = useState([]);
  const [isWishlistLoading, setIsWishListLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSeletedVariant] = useState(null);
  const [searchProduct, setSearchProduct] = useState("");
  const [isLocalSearch, setIsLocalSearch] = useState(false);
  const [loadingProductId, setLoadingProductId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const { isSearchCall, searchData } = useSelector(
    (state) => state?.useSearchData
  );

// let searchData =""
  useEffect(() => {
    // getPriceRangeData();
    getCategoriesOptions();
    getAllgetBrandOptions();
  }, []);
  useEffect(() => {
    getPriceRangeData();
  }, [state?.productid, searchData]);

// local shop search
  useEffect(() => {
    if (isLocalSearch) {
      getAllProducts(
        +state?.productid,
        +state?.brandsUrlId,
        searchProduct,
        currentPage,
        state?.minPriceUrl && state?.maxPriceUrl
          ? {
              min_price_amount: Math.ceil(+state?.minPriceUrl),
              max_price_amount: Math.ceil(+state?.maxPriceUrl),
            }
          : {
              min_price_amount: Math.ceil(minPrice) || 0,
              max_price_amount: Math.ceil(maxPrice) || 0,
            },
        20,
        orderFilter
      );
    }
  }, [isLocalSearch, searchProduct]);

// chip filter
  useEffect(() => {
    if (state?.categoryname) {
      updateSelectedFilters("Category", state?.categoryname);
    }
  }, [state?.categoryname]);
 

  //   price range
  const getPriceRangeData = async () => {
    try {
      const response = await getRangeForPagination();
      if (response.status_code === 200) {
        let priceDetails = {
          ...response?.data,
          min_price_amount: Math.ceil(response?.data?.min_price_amount) || 0,
          max_price_amount: Math.ceil(response?.data?.max_price_amount) || 0,
        };

        setPriceRangeData(priceDetails);
        setMinPrice(
          +state?.minPriceUrl
            ? Math.ceil(+state?.minPriceUrl)
            : Math.ceil(response?.data?.min_price_amount) || 0
        );
        setMaxPrice(
          +state?.maxPriceUrl
            ? Math.ceil(+state?.maxPriceUrl)
            : Math.ceil(response?.data?.max_price_amount) || 0
        );
        setSelectedCategory(+state?.productid);
        setSelectedBrand(+state?.brandsUrlId);

        getAllProducts(
          +state?.productid,
          +state?.brandsUrlId,
          searchData,
          currentPage,
          state?.minPriceUrl && state?.maxPriceUrl
            ? {
                min_price_amount: Math.ceil(+state?.minPriceUrl),
                max_price_amount: Math.ceil(+state?.maxPriceUrl),
              }
            : priceDetails,
          20,
          orderFilter
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  //   categories
  const getCategoriesOptions = async () => {
    try {
      const response = await getCategoriesOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        if (response?.data?.length > 0) {
          setCategoriesOptions(response.data);
        } else {
          setCategoriesOptions([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  // brands
  const getAllgetBrandOptions = async () => {
    try {
      const response = await getBrandOptionsAPICall();
      const status = await response.status_code;
      if (status === 200) {
        if (response?.data?.length > 0) {
          setBrandOptions(response.data);
        } else {
          setBrandOptions([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  //   onchnage handlers
  const handleCategoryChange = (id,selected) => {
    setCurrentPage(0);
    setSelectedCategory(id);
    updateSelectedFilters("Category", selected.category_name);
    dispatch(setSearchData(""))

    getAllProducts(
      id,
      selectedBrand,
      "",
      currentPage,
      { min_price_amount: minPrice, max_price_amount: maxPrice },
      ITEM_PER_PAGE,
      orderFilter
    );
  };
  const handleBrandsOnchange = (id) => {
    setCurrentPage(0);
    setSelectedBrand(id);
 dispatch(setSearchData(""))

    getAllProducts(
      selectedCategory,
      id,
      "",
      currentPage,
      { min_price_amount: minPrice, max_price_amount: maxPrice },

      ITEM_PER_PAGE,
      orderFilter
    );
  };
  const handleSortChange = (event) => {
    setOrderFilter(event.target.value);
    updateSelectedFilters(
      "Sort By",
      event.target.value
    );
    getAllProducts(
      selectedCategory,
      selectedBrand,
      "",
      currentPage,
      { min_price_amount: minPrice, max_price_amount: maxPrice },

      ITEM_PER_PAGE,
      event.target.value
    );
  };
  const getAllProducts = async (
    categoryId,
    brandId,
    search,
    page,
    priceRange,
    numberItems,
    order
  ) => {
    try {
      setProductLoading(true);
      const payload = {
        category_id: categoryId ? categoryId : 0,
        brand_id: brandId ? brandId : 0,
        sort_by: order,
        min: priceRange?.min_price_amount,
        max: priceRange?.max_price_amount +2,
        search_text: search,
        page_number: page * ITEM_PER_PAGE,
        num_of_items: numberItems,
      };

      const response = await getAllProductsAPICall(payload);
      if (response.status_code === 200) {
        setAllProducts(response?.data?.product_data);
        setProductCount(response?.data?.product_count?.product_count);
        setProductLoading(false);
      }
    } catch (error) {
      console.error(error);
      setProductLoading(false);
    }
  };
  //   single product

  const getProductDetails = async (id) => {
    // setQuickViewProductDetails({});
    try {
      const response = await getSingleProduct(id);
      if (response.status_code === 200) {
        setProductDetails(response?.data || {});
      }
    } catch (error) {}
  };
  const getAssetsDetails = async (id) => {
    try {
      const response = await getAssetsForProduct(id);
      if (response.status_code === 200) {
        setAssetsDetails(response?.data || []);
      }
    } catch (error) {}
  };
  const productVarients = async (id) => {
    const response = await getImageVarients(id);
    const variants = response?.data || [];
    if (variants?.length > 0) {
      setProductVarients(variants);
    } else {
      setProductVarients([{ variant_map_id: 0, value: "One Size" }]);
    }
  };
  const handleNextPage = () => {
    if (
      currentPage <
      Math.ceil(priceRangeData?.product_count / ITEM_PER_PAGE) - 1
    ) {
      setCurrentPage(currentPage + 1);
      getAllProducts(
        selectedCategory,
        selectedBrand,
        "",
        currentPage + 1,
        { min_price_amount: minPrice, max_price_amount: maxPrice },

        ITEM_PER_PAGE,
        orderFilter
      );
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      getAllProducts(
        selectedCategory,
        selectedBrand,
        "",
        currentPage - 1,
        { min_price_amount: minPrice, max_price_amount: maxPrice },

        ITEM_PER_PAGE,
        orderFilter
      );
    }
  };

  const gotoFirstPage = () => {
    setCurrentPage(0);
    getAllProducts(
      selectedCategory,
      selectedBrand,
      "",
      0,
      { min_price_amount: minPrice, max_price_amount: maxPrice },

      ITEM_PER_PAGE,
      orderFilter
    );
  };
  const handleResetFilter = () => {
    if (
      state?.productid ||
      state?.brandsUrlId ||
      state?.minPriceUrl ||
      state?.maxPriceUrl
    ) {
      state.productid = 0;
      state.brandsUrlId = 0;
      state.minPriceUrl = null;
      state.maxPriceUrl = null;
      window.history.replaceState({}, "");
    }
    setSelectedCategory(null);
    setSelectedBrand(null);
    setCurrentPage(0);
    setOrderFilter("asc");
    setMinPrice(priceRangeData?.min_price_amount);
    setMaxPrice(priceRangeData?.max_price_amount);
    setSelectedFilters([])
    getAllProducts(
      0,
      0,
      "",
      0,
      {
        min_price_amount: priceRangeData?.min_price_amount,
        max_price_amount: priceRangeData?.max_price_amount,
      },

      ITEM_PER_PAGE,
      "asc"
    );
    window.history.replaceState({}, "");
  };
// chip selected filter
const updateSelectedFilters = (type, value) => {
  setSelectedFilters((prev) => {
    const existingIndex = prev.findIndex((filter) => filter.type === type);
    if (existingIndex >= 0) {
      prev[existingIndex] = { type, value };
      return [...prev];
    }
    return [...prev, { type, value }];
  });
};
// handle remove filter
const handleRemoveFilter =(data) => {
  setSelectedFilters((prev) =>
    prev.filter((filter) => filter.type !== data.type)
  );
  if(data?.type === "Category"){
    setSelectedCategory(null);
    getAllProducts(
      0,
      0,
      "",
      currentPage,
      { min_price_amount: minPrice, max_price_amount: maxPrice },
      ITEM_PER_PAGE,
      orderFilter
    );
  }
  if(data?.type === "Sort By"){
    setOrderFilter("asc");
    getAllProducts(
      selectedCategory,
      0,
      "",
      currentPage,
      { min_price_amount: minPrice, max_price_amount: maxPrice },
      ITEM_PER_PAGE,
      "asc"
    );
  }
  
}

  // Add To Cart

  const addToCart = (productId) => {
    setLoading(true);
    let payload = {
      product_id: productId,
      quantity: quantity || 1,
      variant_id: selectedVariant,
      attribute_id: 0,
    };
    const addToCartResponse = addToCartApi(token, payload);
    addToCartResponse
      .then((data) => {
        if (data?.status === 401) {
          toast.error(data?.message || "Please login");
          setLoading(false);
        } else if (data?.status_code === 200) {
          toast.success(data?.message);
          dispatch(fetchUserData(token));
          setLoading(false);
          setIsQuickView(false);
        } else if (
          data?.response?.data?.data?.error === "product already exists in cart"
        ) {
          toast.error(data?.response?.data?.data?.error || "Please login");
          setLoading(false);
          setIsQuickView(false);
        } else {
          toast.error("Error");
          setLoading(false);
          setIsQuickView(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.data.error);
          setLoading(false);
          setIsQuickView(false);
        } else {
          toast.error("Please Login ");
        }
      });
  };
  // Add to wishlist

  const addWishList = async (id) => {
    setIsWishListLoading(true);
    const wishListResponse = await addToWishList(token, id);
    if (wishListResponse?.status === "success") {
      toast.success(wishListResponse?.message);
      setIsWishListLoading(false);
      setIsQuickView(false);
      setLoadingProductId(null)
    } else if (wishListResponse === 403) {
      toast.error("Product already exists in the wishlist");
      setIsWishListLoading(false);
      setLoadingProductId(null)

    } else {
      toast.error(wishListResponse?.message);
      setIsWishListLoading(false);
      setLoadingProductId(null)

    }
  };
  
  return (
    <ShopContext.Provider
      value={{
        isQuickView,
        setIsQuickView,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        priceRangeData,
        categoriesOptions,
        brandOptions,
        handleCategoryChange,
        selectedCategory,
        setSelectedCategory,
        selectedBrand,
        setSelectedBrand,
        handleBrandsOnchange,
        orderFilter,
        setOrderFilter,
        allProducts,
        getProductDetails,
        productDetails,
        assetsDetails,
        getAssetsDetails,
        setAssetsDetails,
        currentPage,
        setCurrentPage,
        handleNextPage,
        handlePreviousPage,
        gotoFirstPage,
        getAllProducts,
        handleSortChange,
        productVarients,
        productVarientsData,
        setProductVarients,
        handleResetFilter,
        productCount,
        productLoading,
        addWishList,
        isWishlistLoading,
        addToCart,
        quantity,
        setQuantity,
        selectedVariant,
        setSeletedVariant,
        gridView,
        setGridView,
        setSearchProduct,
        setIsLocalSearch,loadingProductId, setLoadingProductId,selectedFilters,setSelectedFilters,handleRemoveFilter
      }}
    >
      {children}
    </ShopContext.Provider>
  );
}

function useShopContext() {
  try {
    return useContext(ShopContext);
  } catch (error) {
    console.error("Error using ViewProductContext:", error);
    return null;
  }
}

export { ShopContext, ShopProvider, useShopContext };
