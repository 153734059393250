import React, { useState } from "react";

const Counter = ({initialValue = 1,
    minValue = 1,
    onChange,isDisabled}) => {
    const [count, setCount] = useState(initialValue);

//   const handleChange = (event) => {
//     const value = Math.max(Number(event.target.value), minValue || 1);
//     setCount(value);
//     onChange?.(value);
//   };

  const increment = () => {
    setCount((prev) => {
      const newCount = prev + 1;
      onChange?.(newCount);
      return newCount;
    });
  };

  const decrement = () => {
    setCount((prev) => {
      const newCount = Math.max(prev - 1, minValue || 1);
      onChange?.(newCount);
      return newCount;
    });
  };
  return (
    <div className="d-flex align-items-center mt-2">
      <button
        type="button"
        style={{ minHeight: "30px", minWidth: "36px",borderRadius:"0px" }}
        className="btn btn-light border  btn-sm qtyDecreaseBtn"
        onClick={
            decrement
        }
        disabled={count === 1}
      >
        -
      </button>
      <span className="qtyCount px-2">{count}</span>
      <button
        type="button"
        style={{ minHeight: "30px", minWidth: "36px" ,borderRadius:"0px"}}
        className="btn btn-light border  btn-sm qtyIncreaseBtn"
        onClick={increment
        }
        disabled={!isDisabled}

      >
        +
      </button>
    </div>
  );
};

export default Counter;
