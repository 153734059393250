import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const apiServices = axios.create({
    baseURL: API_BASE_URL,
  });
// Thunk to fetch menu data
export const fetchMenuData = createAsyncThunk(
  "menu/fetchMenuData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.get('/all-pages');
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch menu data"
      );
    }
  }
);

// Thunk to fetch menu data
export const fetchHeaderMenusData = createAsyncThunk(
  "menu/fetchHeaderMenuData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.get('/main-menus');
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch menu data"
      );
    }
  }
);
const initialState = {
  menuData: [],
  headerMenuData:[],
  loading: false,
  error: null,
  headerLoading: false,
  headerError: null,
};

// Slice definition
const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenuData.fulfilled, (state, action) => {
        state.loading = false;
        state.menuData = action.payload;
      })
      .addCase(fetchMenuData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchHeaderMenusData.pending, (state) => {
        state.headerLoading = true;
      })
      .addCase(fetchHeaderMenusData.fulfilled, (state, action) => {
        state.headerLoading = false;
        state.headerMenuData = action.payload;
      })
      .addCase(fetchHeaderMenusData.rejected, (state) => {
        state.headerLoading = false;
        state.headerError = "redux error";
      });
  },
});
export default menuSlice.reducer;
