import React, { useEffect, useState } from "react";
import "../css/header.css";
import NormalStyledLink from "./NormalStyledLink";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CategoryMainDropdown,
  CategorySubDropdown,
} from "../services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderIcons from "./HeaderIcons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../Redux/Slice/userDeatails";
import { fetchSettingsData } from "../Redux/Slice/SettingsDataSlice";
import { IconButton, useMediaQuery } from "@mui/material";
import SidebarComponent from "./SidebarComponent";
import { setSearchData } from "../Redux/Slice/SearchData";
import { fetchHeaderMenusData, fetchMenuData } from "../Redux/Slice/PageMenu";
import useReducerHook from "../hooks/useReducerHook";
import useFindMenuID from "../hooks/useFindSlug";
import { SHOP } from "../shared/slugs";

const Header = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { isSearchCall, searchData } = useSelector(
    (state) => state?.useSearchData
  );
  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  const { pageMenus } = useReducerHook();
  const siteLogo = settingsData?.find(
    (item) => item?.key_name === "SITE_LOGO"
  )?.key_value;

  const isSmallScreen = useMediaQuery("(max-width: 1793px)");
  const isSmallHederItems = useMediaQuery("(max-width: 1259px)");
  let shopPage = useFindMenuID(pageMenus?.menuData, SHOP);

  const fetchCategories = async () => {
    const responseCategory = await CategoryMainDropdown();
    setCategory(responseCategory.data);

    const responseSubCategory = await CategorySubDropdown();
    setSubCategory(responseSubCategory.data);
  };
  useEffect(() => {
    dispatch(fetchMenuData());
    dispatch(fetchHeaderMenusData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSettingsData());
    fetchCategories();
  }, []);
  useEffect(() => {
    if (token) {
      dispatch(fetchUserData(token));
    }
  }, [token]);

  const handleCategoryClick = (categoryID, category_name) => {
    navigate(`/${shopPage?.page_slug}`, {
      state: { page_id: shopPage?.page_id,productid: categoryID,categoryname: category_name },
    });
  };

  const searchProductsOnchange = async (e) => {
    dispatch(setSearchData(e.target.value));
    navigate(`/${shopPage?.page_slug}`, {
      state: { page_id: shopPage?.page_id},
    });
  };

  return (
    <>
      <header className="header px-2">
        <nav aria-label="Main Navigation" className="Main_Navigation">
          {isSmallHederItems && (
            <div
              className="sidebar-container"
              style={{
                display: "flex",
                margin: "10px",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleOpenSidebar}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <SidebarComponent
            isOpen={isSidebarOpen}
            handleCloseSidebar={() => setSidebarOpen(false)}
            category={category}
            subCategory={subCategory}
          />
          <img
            onClick={() => navigate("/")}
            src={siteLogo ? AppUrl + siteLogo : null}
            alt="logo"
            width={110}
          />
          {!isSmallHederItems && (
            <ul className="nav-list">
              {category &&
                category?.length > 0 &&
                category?.slice(0, 3)?.map((cat) => (
                  <li
                    key={cat.id}
                    className="nav-item"
                    onMouseEnter={() => setHoveredItem(cat)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <span>
                      {cat.category_name}
                    </span>
                    {hoveredItem === cat && (
                      <div
                        className="dropdown-css"
                        onMouseEnter={() => setHoveredItem(cat)}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        {subCategory
                          ?.filter((subCat) => subCat.parent_id === cat.id)
                          ?.map((filteredSubCat) => (
                            <div
                              className="dropdown-column"
                              key={filteredSubCat.id}
                            >
                              <h4
                                className="category-title"
                                onClick={() =>
                                  handleCategoryClick(
                                    filteredSubCat.id,
                                    filteredSubCat.category_name
                                  )
                                }
                              >
                                {filteredSubCat.category_name}
                              </h4>

                              <ul className="child-list">
                                {subCategory
                                  ?.filter(
                                    (subSubCat) =>
                                      subSubCat.parent_id === filteredSubCat.id
                                  )
                                  ?.map((nestedSubCat) => (
                                    <li
                                      className="child-item"
                                      key={nestedSubCat.id}
                                      onClick={() =>
                                        handleCategoryClick(
                                          nestedSubCat.id,
                                          nestedSubCat.category_name
                                        )
                                      }
                                    >
                                      {nestedSubCat.category_name}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          ))}
                      </div>
                    )}
                  </li>
                ))}

              {pageMenus?.headerMenuData?.map((x) => {
                return (
                  <span
                    style={{
                      cursor: "pointer",
                      color:  `/${x?.page_slug}` === location?.pathname ? "#eb0784" : "#0d0d0e",
                      fontSize: "small",
                      fontWeight: "bold",
                      textDecoration: "none",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      navigate(`/${x?.page_slug}`, {
                        state: { page_id: x?.page_id },
                      });
                    }}
                    key={x?.page_id}
                  >
                    {x?.name}
                  </span>
                );
              })}
            </ul>
          )}
        </nav>

        <div className="search-container">
          {!isSmallScreen && (
            <input
              type="text"
              className="search-box"
              placeholder="Search for categories..."
              value={searchData || ""}
              onChange={searchProductsOnchange}
            />
          )}
        </div>

        <HeaderIcons />

        {/* <br /> */}
      </header>

      {isSmallScreen && (
        <div className="mobile-search-bar">
          <input
            value={searchData || ""}
            onChange={searchProductsOnchange}
            type="text"
            className="mobile-search-box"
            placeholder="Find your perfect match... Search for products..."
          />
        </div>
      )}
    </>
  );
};

export default Header;
