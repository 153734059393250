import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../../Redux/Slice/userDeatails";
import toast from "react-hot-toast";
import { updateProfile } from "../../../services/SettingsService";

const Profile = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.userDetails?.userDetails);
  const token = localStorage.getItem("token");
  const [isEditing, setIsEditing] = useState(false);

  const [editDetails, setEditDetails] = useState({});
  const [errors, setErrors] = useState({});
  const handleEditClick = () => {
    setIsEditing(true);
    setEditDetails({
      ...userDetails,
      name: userDetails?.full_name,
      phone: String(userDetails?.phone_no),
      dob: dateConverter(userDetails?.dob),
    });
  };
  const validateForm = () => {

    const newErrors = {};
    if (!editDetails.name || editDetails.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long.";
    }
    if (!editDetails.phone || editDetails.phone.length !== 10) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    if (!editDetails.dob) {
      newErrors.dob = "Date of birth is required.";
    } else {
      const selectedDate = new Date(editDetails.dob);
      const age = new Date().getFullYear() - selectedDate.getFullYear();
      if (age < 13) {
        newErrors.dob = "You must be at least 13 years old .";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return; 
    }
    try {
      let payload = {
        ...editDetails,
        full_name: editDetails?.name,
        phone_no: +editDetails?.phone,
        date_of_birth: `${editDetails?.dob}T00:00:00Z`,
      };

      const response = await updateProfile(token, payload);
      if (response?.status_code === 200) {
        toast.success(response?.message);
        dispatch(fetchUserData(token));
        setIsEditing(false);
        setErrors({})
      }
    } catch (error) {
      toast.error("Error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  function dateConverter(timeStamp) {
    let date = new Date(timeStamp);
    if (isNaN(date.getTime())) {
      return "";
    }
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();

    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate || "";
  }
  const handleKeyPress = (event) => {
    if (!/[0-9]/i.test(event.key)) {
      event.preventDefault();
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleBlur = (e) => {
    const { name } = e.target;
    validateForm(); 
  };
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10 col-sm-12">
          <div className="card shadow-sm p-4 rounded">
            <h2 className="text-center mb-4">Profile Details</h2>
            {isEditing ? (
              <>
                <div className="mb-3 row">
                  <label className="col-12 col-md-4 col-form-label text-md-end">
                    Name:
                  </label>
                  <div className="col-12 col-md-8">
                    <input
                      type="text"
                      name="name"
                      value={editDetails.name}
                      onChange={handleChange}
                      className="form-control"
                      onBlur={handleBlur} 
                    />
                    {errors.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-12 col-md-4 col-form-label text-md-end">
                    Phone:
                  </label>
                  <div className="col-12 col-md-8">
                    <input
                      type="text"
                      name="phone"
                      value={editDetails.phone}
                      onChange={handleChange}
                      className="form-control"
                      onKeyPress={handleKeyPress}
                      maxLength={10}
                      minLength={10}
                      onBlur={handleBlur} 
                      
                    />
                                        {errors.phone && (
                      <div className="text-danger">{errors.phone}</div>
                    )}

                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-12 col-md-4 col-form-label text-md-end">
                    Date of Birth:
                  </label>
                  <div className="col-12 col-md-8">
                    <input
                      type="date"
                      name="dob"
                       max={currentDate}
                      onChange={handleChange}
                      value={editDetails?.dob}
                      className="form-control"
                      onBlur={handleBlur} 
                    />
                    {errors.dob && (
                      <div className="text-danger">{errors.dob}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 text-center">
                    <button
                      onClick={handleSaveClick}
                      className="btn btn-success w-100"
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-6 text-center">
                    <button
                      onClick={() =>{

        setErrors({})
        setIsEditing(false)
                      } }
                      className="btn btn-secondary w-100"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mb-3 row">
                  <label className="col-12 col-md-4 col-form-label text-md-end">
                    <strong>Name:</strong>
                  </label>
                  <div className="col-12 col-md-8">
                    <p className="pt-2">{userDetails?.full_name}</p>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-12 col-md-4 col-form-label text-md-end">
                    <strong>Email:</strong>
                  </label>
                  <div className="col-12 col-md-8">
                    <p className="pt-2">{userDetails?.email}</p>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-12 col-md-4 col-form-label text-md-end">
                    <strong>Phone:</strong>
                  </label>
                  <div className="col-12 col-md-8">
                    <p className="pt-2">{userDetails?.phone_no}</p>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-12 col-md-4 col-form-label text-md-end">
                    <strong>DOB:</strong>
                  </label>
                  <div className="col-12 col-md-8">
                    <p className="pt-2">{dateConverter(userDetails?.dob) || "--"}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <button
                      onClick={handleEditClick}
                      className="btn btn-primary w-100"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
