import { useNavigate } from "react-router-dom";

const useNavigateShop = () => {
  const navigate = useNavigate();

  const navigateShop = (url,slugData) => {

    if (url && slugData) {
      const parts = url.split("/");
      const productid = parts[0];
      const brandsUrlId = parts[1];
      const minPriceUrl = parts[2];
      const maxPriceUrl = parts[3];

      navigate(`/${slugData?.page_slug}`, {
        state: { page_id: slugData?.page_id, productid, brandsUrlId, minPriceUrl, maxPriceUrl },
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return { navigateShop };
};

export default useNavigateShop;
