import { useSelector } from "react-redux";

const useReducerHook = () => {
  const { pageData, pageMenus, activeSections } = useSelector((state) => state);
  return {
    pageData,
    pageMenus,
    activeSections,
  };
};

export default useReducerHook;
