import React from "react";
import { useNavigate } from "react-router-dom";
import useNavigateMenuId from "../../hooks/useMenuId";
import useReducerHook from "../../hooks/useReducerHook";
import useFindMenuID from "../../hooks/useFindSlug";
import { SHOP } from "../../shared/slugs";

const EmptyCard = () => {
  const {navigatePageId}= useNavigateMenuId();
  const { pageMenus } = useReducerHook();

  let shop = useFindMenuID(pageMenus?.menuData, SHOP);
  return (
    <div className="text-center my-5">
      <h5>YOUR WISHLIST IS EMPTY</h5>
      <button
        className="btn btn-primary my-3"
        onClick={() => navigatePageId(shop)}
      >
        CONTINUE SHOPPING
      </button>
    </div>
  );
};

export default EmptyCard;
