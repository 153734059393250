export const ACCOUNT = "account";
export const WISHLIST = "wish_list";
export const CART = "cart";
export const REGISTER = "register";
export const HOME = "home";
export const SHOP = "shop";
export const BLOGS = "blogs";
export const ABOUT = "about";
export const CONTACT_US = "contact";
export const FAQ = "faq";
export const COMPARE = "compare";
export const VIEW_PRODUCT_DETAILS = "product_details";
export const LOGIN = "login";
export const CAMPAIGN  = "campaign";