import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AddressSchema } from "./validation";
import { fetchCartAmountDetials } from "../../../Redux/Slice/cartSlice";
import {
  createBillingAddressApi,
  createShippingAddressApi,
  deleteBillingAddress,
  deleteShippingAddress,
  getBillingAddress,
  getShippingddress,
  updateBillingAddressApi,
  updateShippingAddressApi,
} from "../../../services/AddressService";
import { placeOrderCreate } from "../../../services/CartService";
import AddressDetails from "./AddressList";
import { FaTag } from "react-icons/fa";
import { MdDelete, MdKeyboardArrowRight } from "react-icons/md";
import AddressForm from "../../../components/AddressForm/AddressForm";
import AppModal from "../../../components/AppModal/AppModal";
const billingInitialValues = {
  name: "",
  mobile_no: "",
  email: "",
  address_line_one: "",
  address_line_two: "",
  pincode: "",
  country_id: "",
  state_id: "",
  city_id: "",
  is_default: false,
    area:""
};
const shippingInitialValues = {
  name: "",
  mobile_no: "",
  email: "",
  address_line_one: "",
  address_line_two: "",
  pincode: "",
  country_id: "",
  state_id: "",
  city_id: "",
  is_default: false,
    area:""
};
const AddressBook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAddressForm, setIsAddressForm] = useState(false);
  const token = localStorage.getItem("token");
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [activeTab, setActiveTab] = useState("description");
  const [mode, setMode] = useState("Add");
  const [isDisabled, setIsDisabled] = useState(false);
  const[isDeleteAddress,setIsDeleteAddress]=useState(false)
  const[selectedAddress,setSelectedAddress]=useState({})
  const handleTabClick = (tab) => {
    if (mode !== "Edit") {
      setActiveTab(tab);
    }
  };
  const billingAddressFormik = useFormik({
    initialValues: billingInitialValues,
    validationSchema: AddressSchema,
    onSubmit: handleBillingSubmit,
  });
  const shippingAddressFormik = useFormik({
    initialValues: shippingInitialValues,
    validationSchema: AddressSchema,
    onSubmit: handleShippingSubmit,
  });

  useEffect(() => {
    dispatch(fetchCartAmountDetials(token));
  }, []);
  useEffect(() => {
    getShippingAddressData(token);
    getBillingAddressData(token);
  }, []);

  const getShippingAddressData = async (token) => {
    try {
      const response = await getShippingddress(token);
      if (response?.status_code === 200) {
        setShippingAddress(response?.data || []);
      }
    } catch (error) {}
  };
  const getBillingAddressData = async (token) => {
    try {
      const response = await getBillingAddress(token);
      if (response?.status_code === 200) {
        setBillingAddress(response?.data || []);
      }
    } catch (error) {}
  };
  const [pincodeValidationError, setPincodeValidationError] = useState("");


  async function handleBillingSubmit(values) {

    // setIsDisabled(true);
    // pincodeValidation(values).then(async (x) => {
    //   if (
    //     Object.keys(billingAddressFormik.errors)?.length === 0 &&
    //     x === "Success"
    //   ) {
    //     setPincodeValidationError("");

        
    //   }
    // });

    let billingData = {
      id: values?.id,
      billing_address_id: values?.id,
      billing_name: values?.name,
      billing_email: values?.email,
      billing_phone_no: values?.mobile_no,
      billing_address_line_one: `${values?.address_line_one}`,
      billing_address_line_two: values?.address_line_two,
      billing_pincode: values?.pincode,
      billing_set_default: values?.is_default == true ? 1 : 0,
      billing_area : values?.area,
      billing_country: values?.country_id,
      billing_state: values?.state_id,
      billing_city: values?.city,
      billing_is_active: 1,
    };
    try {
      let billing = function modeAPIBillingCall() {
        if (billingData?.id) {
          return updateBillingAddressApi(billingData, token);
        } else {
          return createBillingAddressApi(billingData, token);
        }
      };
      let apicall = billing();
      const response = await apicall;
      //   const response = await createBillingAddressApi(billingData, token);
      if (response.status_code == 200) {
        toast.success(
          `Billing address ${
            billingData?.id ? "Updated" : "Added"
          }  successfully!`
        );
        getBillingAddressData(token);
        if (mode === "Edit") {
          setIsAddressForm(false);
        }
        setActiveTab("specification");
        setIsDisabled(false);
      }
    } catch (error) {
      setIsDisabled(false);
    }



  }
  async function handleShippingSubmit(values) {
    let shippingvalues = {
      id: values?.id,
      shipping_address_id: values?.id,
      shipping_name: values?.name,
      shipping_email: values?.email,
      shipping_phone_no: values?.mobile_no,
      shipping_address_line_one: `${values?.address_line_one}`,
      shipping_address_line_two: values?.address_line_two,
      shipping_pincode: values?.pincode,
      shipping_set_default: values?.is_default == true ? 1 : 0,
      shipping_area : values?.area,
      shipping_country: values?.country_id,
      shipping_state: values?.state_id,
      shipping_city: values?.city,
      shipping_is_active: 1,
    };
let editShippingValues ={
...shippingvalues,
address_line_two : shippingvalues?.shipping_address_line_two
}
    try {
      let shipping = function modeAPIShippingCall() {
        if (shippingvalues?.id) {
          return updateShippingAddressApi(editShippingValues, token);
        } else {
          return createShippingAddressApi(shippingvalues, token);
        }
      };
      let apicall = shipping();
      const response = await apicall;
      //   const response = await createShippingAddressApi(
      //     shippingvalues,
      //     token
      //   );
      if (response.status_code == 200) {
        toast.success(
          `Shipping address ${
            shippingvalues?.id ? "Updated" : "Added"
          }  successfully!`
        );
        getShippingAddressData(token);
        setIsAddressForm(false);
        setActiveTab("description");
        billingAddressFormik.resetForm();
        shippingAddressFormik.resetForm();
        setIsDisabled(false);

      }
    } catch (error) {
      setIsDisabled(false);

    }
  }

  const handleBillingShippingSame = (e) => {
    shippingAddressFormik.setFieldValue("terms_agree", e.target.checked);
    if (e.target.checked) {
      shippingAddressFormik.setValues(billingAddressFormik.values);
    } else {
      shippingAddressFormik.resetForm();
    }
  };
  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState(null);

  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState(null);

  const handleBillingAddressSelect = (id) => {
    setSelectedBillingAddressId(id);
  };

  const handleShippingAddressSelect = (id) => {
    setSelectedShippingAddressId(id);
  };
  useEffect(() => {
    let defaultBillingAddressId = billingAddress?.find(
      (x) => x?.billing_set_default === 1
    );
    let defaultShippingAddressId = shippingAddress?.find(
      (x) => x?.shipping_set_default === 1
    );

    setSelectedBillingAddressId(defaultBillingAddressId?.billing_address_id);
    setSelectedShippingAddressId(defaultShippingAddressId?.shipping_address_id);
  }, [billingAddress, shippingAddress]);

  const handleEditForm = (data) => {
    setIsAddressForm(true);
    setMode("Edit");
    let billingData = {
      id: data?.billing_address_id,
      name: data?.billing_name,
      email: data?.billing_email,
      mobile_no: data?.billing_phone_no,
      address_line_one: `${data?.billing_address_line_one}`,
      address_line_two: data?.billing_address_line_two,
      pincode: data?.billing_pincode,
      is_default: data?.billing_set_default == 1 ? true : false,
      country: data?.billing_country,
      country_id: data?.billing_country,
      state_id: data?.billing_state,
      city_id: data?.billing_city,

      state: data?.billing_state,
      city: data?.billing_city,
      state_name: data?.billing_state,
      city_name: data?.billing_city,
      area : data?.billing_area
    };
    let shippingData = {
      id: data?.shipping_address_id,
      name: data?.shipping_name,
      email: data?.shipping_email,
      mobile_no: data?.shipping_phone_no,
      address_line_one: `${data?.shipping_address_line_one}`,
      address_line_two: data?.shipping_address_line_two,
      pincode: data?.shipping_pincode,
      is_default: data?.shipping_set_default == 1 ? true : false,
      country: data?.shipping_country,
      country_id: data?.shipping_country,
      state_id: data?.shipping_state,
      city_id: data?.shipping_city,

      state: data?.shipping_state,
      city: data?.shipping_city,
      state_name: data?.shipping_state,
      city_name: data?.shipping_city,
      area : data?.shipping_area
    };
    if (data?.billing_address_id) {
      setActiveTab("description");
      billingAddressFormik.setValues(billingData);
    } else {
      setActiveTab("specification");
      shippingAddressFormik.setValues(shippingData);
    }
  };
  const handleIsDelete=(data)=>{
    setIsDeleteAddress(true);
    setSelectedAddress(data);
  }
  const handleDeleteAddress =async ()=>{
     
  try {
    let shipping = function modeAPIShippingCall() {
      if (selectedAddress?.billing_address_id) {
        return deleteBillingAddress(selectedAddress?.billing_address_id, token);
      } else {
        return deleteShippingAddress(selectedAddress?.shipping_address_id, token);
      }
    };
    let apicall = shipping();
    const response = await apicall;
  
    if (response.status_code == 200) {
      toast.success(
        selectedAddress?.billing_address_id ? `Billing Address deleted successfully!`:`Shipping Address deleted successfully!`
      );
      if(selectedAddress?.billing_address_id ){
        getBillingAddressData(token);

      }else{
        getShippingAddressData(token);

      }
      setIsDeleteAddress(false);
      
    }
  } catch (error) {
    setIsDisabled(false);

  }
  
  }
  return (
    <div className="container-fluid card p-4 ">
      <div className="row">
        <div className="col-md-10 col-lg-12">
          <h3 className="text-center mb-4">Address Book</h3>
          <h5
            style={{
              fontSize: "12px",
              color: "#535766",
              fontWeight: 700,
              margin: "30px 0px 16px 2px",
            }}
          >
            Billing and Shipping Address
          </h5>
          <div
            className="mt-3"
            style={{
              minHeight: "500px",
              overflow: "auto",
              scrollBehavior: "smooth",
            }}
          >
            <div className="d-flex  justify-content-between">
              {(shippingAddress?.length === 0 ||
                billingAddress?.length === 0) && (
                <p className="text-center" style={{ fontSize: "12px" }}>
                  No Address Available
                </p>
              )}
              <div>
                {billingAddress?.length > 0 &&
                  billingAddress?.map((x) => {
                    return (
                      <div className="mt-2">
                        <AddressDetails
                          id={x?.billing_address_id}
                          isDefault={
                            x?.billing_set_default === 1 ? true : false
                          }
                          name={x?.billing_name || ""}
                          addressTag={"Billing"}
                          addressOne={x?.billing_address_line_one}
                          addressTwo={x?.billing_address_line_two}
                          country={x?.billing_country}
                          state={x?.billing_state}
                          city={x?.billing_city}
                          pincode={x?.billing_pincode}
                          mobileNo={x?.billing_phone_no}
                          selectedId={selectedBillingAddressId}
                          handleAddressSelect={handleBillingAddressSelect}
                          handleEdit={() => handleEditForm(x)}
                          handleDelete={()=>handleIsDelete(x)}

                        />
                      </div>
                    );
                  })}
              </div>
              <div>
                {shippingAddress?.length > 0 &&
                  shippingAddress?.map((x) => {
                    return (
                      <div className="mt-2">
                        <AddressDetails
                          id={x?.shipping_address_id}
                          isDefault={
                            x?.shipping_set_default === 1 ? true : false
                          }
                          name={x?.shipping_name || ""}
                          addressTag={"Shipping"}
                          addressOne={x?.shipping_address_line_one}
                          addressTwo={x?.shipping_address_line_two}
                          country={x?.shipping_country}
                          state={x?.shipping_state}
                          city={x?.shipping_city}
                          pincode={x?.shipping_pincode}
                          mobileNo={x?.shipping_phone_no}
                          selectedId={selectedShippingAddressId}
                          handleAddressSelect={handleShippingAddressSelect}
                          handleEdit={() => handleEditForm(x)}
                          handleDelete={()=>handleIsDelete(x)}

                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-between pointer"
            style={{
              border: "1px solid #eaeaec",
              marginTop: "20px",
              padding: "5px",
            }}
            onClick={() => setIsAddressForm(true)}
          >
            <button className="btn border-0 fw-bold cart-action-btn ">
              <FaTag /> Add New Address
            </button>
            <div>
              <MdKeyboardArrowRight size={25} style={{}} />
            </div>
          </div>
        </div>
      </div>
      {isAddressForm && (
        <AppModal
          title="Add New Address"
          size="lg"
          show={isAddressForm}
          onHide={() => {
            setIsAddressForm(false);
            billingAddressFormik.resetForm();
            shippingAddressFormik.resetForm();
            setActiveTab("description");
            setMode("Add");
          }}
          closeButton={true}
        >
          <div className="tab tab-nav-simple mt-1">
            <ul className="nav nav-tabs justify-content-center" role="tablist">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "description" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("description")}
                >
                  Billing
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "specification" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("specification")}
                >
                  Shipping
                </button>
              </li>
            </ul>
            <div className="tab-content">
              {activeTab === "description" && (
                <div className="tab-pane active in" id="product-tab-additional">
                  <AddressForm
                    activeTab={activeTab}
                    formik={billingAddressFormik}
                    pincodeValidationError={pincodeValidationError}
                    isEmailDisable={mode === "Edit"}
                    isDefaultDisable = {mode === "Edit"}
                  />
                </div>
              )}
              {activeTab === "specification" && (
                <div className="tab-pane active in" id="product-tab-additional">
                  <AddressForm
                    activeTab={activeTab}
                    formik={shippingAddressFormik}
                    handleBillingShippingSame={handleBillingShippingSame}
                    pincodeValidationError={pincodeValidationError}
                    isEmailDisable={mode === "Edit"}
                    isDefaultDisable = {mode === "Edit"}
                  />
                </div>
              )}
            </div>
          </div>
        </AppModal>
      )}
         <AppModal
        size="xs"
        title=""
        show={isDeleteAddress}
        onHide={() => setIsDeleteAddress(false)}
      >
        <div className="p-4 color-brand-3">
        <div className="text-center mb-2">
        <MdDelete  style={{color: "var(--primary-color)"}} size={60}  />

        </div>
 

          <p className="text-center pb-2">
          Are you sure you want to delete this address? This action cannot be undone.
         
          </p>
          <div className="d-flex justify-content-center mt-2 gap-2">
            <button
              className="btn btn-primary bg-dark border-0"
              onClick={() => {
                setIsDeleteAddress(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary bg-dark border-0"
              onClick={() => {
                handleDeleteAddress();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </AppModal>
    </div>
  );
};

export default AddressBook;
