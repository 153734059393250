import React, { useEffect, useState } from "react";
import { getImageVarients } from "../../services/apiServices";

const ProductaSizeVaraiant = ({ id, setVariantIdInParent }) => {
  const [productVariantsData, setProductVariants] = useState([]);
  const [selectedVariantId, setSelectedVariantId] = useState(null);

  useEffect(() => {
    const fetchProductVariants = async () => {
      try {
        const response = await getImageVarients(id);
        const variants = response.data || [];
        setProductVariants(variants);
      } catch (error) {
        console.error("Error fetching product variants:", error);
      }
    };
if(id){
  fetchProductVariants();
}
   
  }, [id]);

  const handleVariantClick = (variantId) => {
    setSelectedVariantId(variantId);
    setVariantIdInParent(variantId);
  };
  
  return (
    <div className="size-options">
      <p style={{ textTransform: "uppercase", fontSize: "12px" }}>
        <strong>Select Size</strong>
      </p>
      <div>
        {productVariantsData && productVariantsData.length > 0 ? (
          productVariantsData.map((variant) => (
            <button
              key={variant.variant_map_id}
              onClick={() => handleVariantClick(variant.variant_map_id)}
              className={
                variant.variant_map_id === selectedVariantId ? "selected" : ""
              }
              style={{
                margin: "2px",
                padding: "5px 15px",
                cursor: "pointer",
                border:
                  variant.variant_map_id === selectedVariantId
                    ? "1px solid #E6365B"
                    : "1px solid gray",
                backgroundColor:
                  variant.variant_map_id === selectedVariantId
                    ? "#E6365B"
                    : "#fff",
                color:
                  variant.variant_map_id === selectedVariantId
                    ? "white"
                    : "black",
              }}
            >
              {variant.value}
            </button>
          ))
        ) : (
          <button
            onClick={() => handleVariantClick(1)}
            style={{
              margin: "2px",
              padding: "5px 15px",
              cursor: "pointer",
              border: "1px solid gray",
              border:
              selectedVariantId === 1
                ? "1px solid #E6365B"
                : "1px solid gray",
            backgroundColor:
              selectedVariantId === 1
                ? "#E6365B"
                : "#fff",
            color:
              selectedVariantId === 1
                ? "white"
                : "black",
            }}
          >
            One Size
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductaSizeVaraiant;
