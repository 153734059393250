import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useReducerHook from "../hooks/useReducerHook";
import { useLocation } from "react-router-dom";
import { fetchPageData } from "../Redux/Slice/SectionSlice";

// Sections and Extra Sections
import Section1 from "./Home/Section1/Section1";
import Section2 from "./Home/Section2/Section2";
import Section3 from "./Home/Section3/Section3";
import Section4 from "./Home/Section4/Section4";
import Section5 from "./Home/Section5/Section5";
import Section6 from "./Home/Section6/Section6";
import Section7 from "./Home/Section7/Section7";
import Section8 from "./Home/Section8/Section8";
import Section9 from "./Home/Section9/Section9";
import Section10 from "./Home/Section10/Section10";
import Section11 from "./Home/Section11/Section11";
import Section12 from "./Home/Section12/Section12";
import Section13 from "./Home/Section13/Section13";
import Section14 from "./Home/Section14/Section14";
import Section15 from "./ExtraSections/ExtraSection1/ExtraSection1";
import Section16 from "./ExtraSections/ExtraSection2/ExtraSection2";

import Section17 from "./ExtraSections/ExtraSection3/ExtraSection3";
import Section18 from "./ExtraSections/ExtraSection4/ExtraSection4";
import Section19 from "./ExtraSections/Section15/Section15";

// Shop
import Section20 from "./Shop1/Shop/Shop";

// Contact
import Section21 from "./contact-us/ContactUs";

// Blogs
import Section22 from "./blog/Blog";

// faq
import Section23 from "./faq/Faq";

// Cart
import Section24 from "./cart/Cart";

// Wishlist
import Section25 from "./Wishlist/Wishlist";

// Account
import Section26 from "./Dashboard/DashboardPage/Dashboard";

// Login
import Section27 from "../components/login/Login";

// Register
import Section28 from "./Shop/SingleProductPage";
import Section29 from "./Campaign/Campaign";


const AllPages = () => {
  const dispatch = useDispatch();
  const { pageData } = useReducerHook();
  const location = useLocation();
  const page_id = location?.state?.page_id || 1;

  const { search } = useLocation();
  let queryParams = search?.split("?")[1]?.split("&") || [];
  const decodedPageId = atob(queryParams?.length > 0 ? queryParams[1] : "");
  useEffect(() => {
    if (decodedPageId) {
      dispatch(fetchPageData(decodedPageId));
    } else {
      dispatch(fetchPageData(page_id));
    }
    // if (page_id) {
    //   dispatch(fetchPageData(page_id));
    // }
  }, [page_id, dispatch, search]);
  const renderComponent = (sections) => {
    if (!sections) return null;

    return Object.keys(sections).map((placeOrderKey) => {
      const placeOrder = sections[placeOrderKey];
      return Object.keys(placeOrder).map((sectionName) => {
        const sectionGroups = placeOrder[sectionName];
        return Object.keys(sectionGroups).map((sectionGroup) => {
          switch (sectionName) {
            case "section_1":
              return (
                <>
                  <Section1
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                </>
              );
            case "section_2":
              return (
                <Section2
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_3":
              return (
                <Section3
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_4":
              return (
                <Section4
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_5":
              return (
                <Section5
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_6":
              return (
                <Section6
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_7":
              return (
                <Section7
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_8":
              return (
                <Section8
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_9":
              return (
                <Section9
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_10":
              return (
                <Section10
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_11":
              return (
                <Section11
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_12":
              return (
                <Section12
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_13":
              return (
                <Section13
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_14":
              return (
                <Section14
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_15":
              return (
                <Section15
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
              case "section_16":
                return (
                  <Section16
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_17":
                return (
                  <Section17
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_18":
                return (
                  <Section18
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_19":
                return (
                  <Section19
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_20":
                return (
                  <Section20
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_21":
                return (
                  <Section21
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_22":
                return (
                  <Section22
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_23":
                return (
                  <Section23
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_24":
                return (
                  <Section24
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_25":
                return (
                  <Section25
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_26":
                return (
                  <Section26
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_27":
                return (
                  <Section27
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
              case "section_28":
                return (
                  <Section28
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
                case "section_29":
                return (
                  <Section29
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );
            default:
              return null;
          }
        });
      });
    });
  };
  return <>{renderComponent(pageData?.sectionData)}</>;
};

export default AllPages;
