import { useMemo } from "react";

const useFindMenuID = (data, key) => {
  const result = useMemo(() => {
    if (!Array.isArray(data) || !key) return null;

    const item = data?.find((item) => item?.page_slug === key);

    return item ? item : null;
  }, [data, key]);

  return result;
};

export default useFindMenuID;
