import React, { lazy, Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import './css/ResposiveImages.css'
import Checkout from "./pages/Checkout/Checkout";
import { Toaster } from "react-hot-toast";
import ForgetPassword from "./components/forgetpassword/ForgetPassword";
import ProtectedRoute from "./components/ProductRoute/ProtectedRoute";
import { useSelector } from "react-redux";
import AllPages from "./pages/AllPages";
import Otp from "./components/otp/Otp";
import AppLayout from "./components/layouts/AppLayout";
import Pages from "./pages/Suppages/Pages";
import Loader from "./components/Loader";
import CheckoutAddress from "./pages/CheckoutAddress/Checkout"
const App = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  const primaryColor = settingsData?.find(
    (item) => item?.key_name === "PRIMARY_COLOR"
  )?.key_value;
  const secondryColor = settingsData?.find(
    (item) => item?.key_name === "SECONDARY_COLOR"
  )?.key_value;
  const darkColor = settingsData?.find(
    (item) => item?.key_name === "DARK_COLOR"
  )?.key_value;
  const lightColor = settingsData?.find(
    (item) => item?.key_name === "LIGHT_COLOR"
  )?.key_value;
  const siteName = settingsData?.find(
    (item) => item?.key_name === "SITE_NAME"
  )?.key_value;
  const sitelogo = settingsData?.find(
    (item) => item?.key_name === "SITE_LOGO"
  )?.key_value;
  useEffect(() => {
    if (siteName) {
      document.title = siteName;
    }
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    const faviconUrl = AppUrl + sitelogo;
    link.href = faviconUrl;
    const root = document.documentElement;
    root.style.setProperty("--primary-color-gentlemenz", primaryColor);
    root.style.setProperty("--secondry-color-gentlemenz", secondryColor);
    root.style.setProperty("--hover-color-gentlemenz", darkColor);
    root.style.setProperty("--primary-light-color-gentlemenz", lightColor);
  }, [settingsData]);

  const routes = [
    {
      path: "",
      element: <AppLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/home" />,
        },
        {
          path: "/otp-verfiy",
          element: <Otp />,
        },
        {
          path: "/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/sup-page",
          element: <Pages />,
        },
        {
          path: "/checkout/cart",
          element: (
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          ),
        },
        {
          path: "/checkout/address",
          element: (
            <ProtectedRoute>
              <CheckoutAddress />
            </ProtectedRoute>
          ),
        },
        {
          path: "/:slug",
          element: <AllPages />,
        },
        // {
        //   path: "/captcha",
        //   element: <Captcha/>,
        // },
      ],
    },
  ];
  return (
    <>
      <Toaster
        toastOptions={{
          style: {},
        }}
      />
      <Suspense fallback={<Loader/>}>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((childRoute, childIndex) => (
                  <Route
                    key={childIndex}
                    path={childRoute.path}
                    element={childRoute.element}
                  />
                ))}
            </Route>
          ))}
        </Routes>
      </Suspense>
    </>
  );
};
export default App;
