import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../pages/Footer/Footer";
import Header from "../Header";

const AppLayout = () => {
  return (
    <div>
      <Header />

      <div className="main">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default AppLayout;
