import React from "react";
import { APP_URL } from "../../../shared/constants";
import useNavigateShop from "../../../hooks/useNavigateShop";
import useReducerHook from "../../../hooks/useReducerHook";
import useFindMenuID from "../../../hooks/useFindSlug";
import { SHOP } from "../../../shared/slugs";

const Section3 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();
  function consolidatedData() {
    let res = {
      img1: "",
      resImag1: "",
      img2: "",
      resImag2: "",
      linkone: "",
      linktwo: "",
    };
    if (sections?.length > 0) {
      let ads = sections?.find((x) => x?.key_name === "ADS")?.ads_data;
      res.img1 = ads?.[0]?.image_path;
      res.resImag1 = ads?.[0]?.responsive_image_path;
      res.img2 = ads?.[1]?.image_path;
      res.resImag2 = ads?.[1]?.responsive_image_path;
      res.linkone = ads?.[0]?.link_one;
      res.linktwo = ads?.[1]?.link_one;
    }
    return res;
  }
  const { pageMenus } = useReducerHook();
  let shopPage = useFindMenuID(pageMenus?.menuData, SHOP);
  return (
    <div className="container-fluid my-3">
      <div className="row">
        <div className="col-12 col-lg-6">
          <img
            src={APP_URL + consolidatedData()?.img1}
            alt=""
            className="img-fluid d-view"
            onClick={() => navigateShop(consolidatedData()?.linkone, shopPage)}
          />
          <img
            src={APP_URL + consolidatedData()?.resImag1}
            alt=""
            className="img-fluid m-view"
            onClick={() => navigateShop(consolidatedData()?.linkone, shopPage)}
          />
        </div>
        <div className="col-12 col-lg-6">
          <img
            src={APP_URL + consolidatedData()?.img2}
            alt=""
            className="img-fluid d-view"
            onClick={() => navigateShop(consolidatedData()?.linktwo, shopPage)}
          />
          <img
            src={APP_URL + consolidatedData()?.resImag2}
            alt=""
            className="img-fluid m-view"
            onClick={() => navigateShop(consolidatedData()?.linkone, shopPage)}
          />
        </div>
      </div>
    </div>
  );
};

export default Section3;
