import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getCountryList = async () => {
    try {
      const response = await apiService.get("/get-countries");
      return response.data;
    } catch (error) {}
  };
  export const getStateList = async (countryId) => {
    try {
      const response = await apiService.get(`/get-states/${countryId}`);
      return response.data;
    } catch (error) {}
  };
  export const getCityList = async (stateId) => {
    try {
      const respones = await apiService.get(`/get-cities/${stateId}`);
      return respones.data;
    } catch (error) {}
  };
  export const createBillingAddressApi = async (payload,token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await apiService.post(
        "/create-billing-address",
        payload,
        config
      );
      return response.data;
    } catch (error) { }
  };
  export const createShippingAddressApi = async (payload,token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await apiService.post(
        "/create-shipping-address",
        payload,
        config
      );
      return response.data;
    } catch (error) { }
  };
  export const getBillingAddress = async (token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiService.get("/get-billing-addresses", config);
      return response.data;
    } catch (error) { }
  };
  
  //
  export const getShippingddress = async (token) => {
    try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      const response = await apiService.get("/get-shipping-addresses", config);
      return response.data;
    } catch (error) { }
  };
  export const updateShippingAddressApi = async (payload,token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await apiService.post(
        "/update-shipping-address",
        payload,
        config
      );
      return response.data;
    } catch (error) { }
  };
  export const updateBillingAddressApi = async (payload,token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await apiService.post(
        "/update-billing-address",
        payload,
        config
      );
      return response.data;
    } catch (error) { }
  };
  export const deleteBillingAddress = async (addressId, token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiService.get(
        `/delete-billing-address-by-id/${addressId}`,
        config
      );
      return response.data;
    } catch (error) {}
  };
  export const deleteShippingAddress = async (addressId, token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiService.get(
        `/delete-shipping-address-by-id/${addressId}`,
        config
      );
      return response.data;
    } catch (error) {}
  };