import React from "react";
import "./Card.css";

import { useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import useReducerHook from "../../hooks/useReducerHook";
import useFindMenuID from "../../hooks/useFindSlug";
import { VIEW_PRODUCT_DETAILS } from "../../shared/slugs";

const Card = ({
  productImg,
  brandName,
  productDes,
  originalPrice,
  DiscountPrice,
  width = 280,
  height = 315,
  productDetails,
  handleViewProduct,
  handleWishlist,
  handleRemoveWishlist
}) => {
  const navigate = useNavigate();

  const {pageMenus}=useReducerHook();
  let viewproductID = useFindMenuID(pageMenus?.menuData, VIEW_PRODUCT_DETAILS);


  const routeToViewCardList = () => {
    navigate(`/${viewproductID?.page_slug}`, {
      state: {
        page_id: viewproductID?.page_id,
        productId: parseInt(productDetails?.product_id),
      },
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className="wrapper dy_product-w-p"
      style={{ border: "1px solid #eaeaec", padding: "8px" }}
    >
      <div className="product">
        <figure className="product-media">
          <a style={{ cursor: "pointer" }}>
            <img
              src={productImg}
              alt={productDes}
              width={width}
              height={height}
              onClick={routeToViewCardList}
            />
          </a>

          <div className="product-action-vertical">
            <div
              className="btn-product-icon btn-wishlist"
              title="Remove Wishlist"
              style={{ cursor: "pointer" }}
              onClick={() => handleRemoveWishlist(productDetails)}
            >
              <MdCancel style={{ fontSize: "1rem" }} />
            </div>
          </div>
        </figure>
        <div className="product-details">
          <div className="product-cat">
            <a href="#"> {brandName} </a>
          </div>
          <h3 className="product-name" onClick={routeToViewCardList}>
            <a style={{ cursor: "pointer" }}>{productDes}</a>
          </h3>
          <div className="wish-product-price">
            <ins className="new-price">Rs. {originalPrice}</ins>
          </div>
          <button
            className="w-100 btn btn-primary action-btn border-0 mt-3 "
            onClick={routeToViewCardList}
          >
            MOVE TO BAG
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
