import React, { useState } from 'react'
import { Button, unstable_createBreakpoints } from '@mui/material';
import {  Link, useNavigate } from 'react-router-dom'
import { login, verifyOtp } from '../../services/apiServices';
import toast from 'react-hot-toast';
import { Alert } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { IoEyeOffOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { Co2Sharp } from '@mui/icons-material';

function LoginForm({setFormSwitch,handleOpenOtpModal,setError,error}) {
  const localEmail = localStorage.getItem('email');
    const navigate =  useNavigate()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [showPassword ,setShowPassword]  = useState(false)
    const validateLoginForm = () => {
            const newErrors = {};
            if (!email) {
            newErrors.email = "Email is required.";
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                newErrors.email = "Please enter a valid email.";
            }

            if (!password) {
                newErrors.password = "Password is required.";
            } else if (password.length < 6) {
                newErrors.password = "Password must be at least 6 characters.";
            }
            return newErrors;
    };



    const resendOtp =  async() =>{
      const payload = localEmail

      try {
        const res = await verifyOtp(payload)
        console.log(res)
        handleOpenOtpModal()
        
        toast.success(
          "OTP sent to out email id"
        )
      } catch (error) {
        
        toast.error("Error in Getting OTP")
      }


    }

  const handleLoginSubmit = async(event) => {
    event.preventDefault();
    const errors = validateLoginForm();
    
    if (Object.keys(errors).length === 0 ) {
        const payload = {
            email,
            password
        }
            try {
                const res  = await login(payload)
                if(res.status_code === 200){
                    localStorage.setItem("token", res.token.token);
                    toast.success(res.message)
                    navigate("/");
                }
            } catch (error) {
              console.log(error,"error");
              
                if (error.response.data.data.error === "first verify your email") {
                  localStorage.setItem('email',email)
                    setError(
                      <div
                        style={{
                          backgroundColor: "#f8d7da",
                          borderColor: "#f5c6cb",
                          borderRadius: "5px",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={resendOtp}
                      >
                        <p className="text-danger">
                          Email not verified. Please verify your email. Click on{" "}
                          <strong>
                            {" "}
                            Resend Otp
                          </strong>
                        </p>
                      </div>
                    );
                  } else {
                    setError("Email or password is incorrect");
                  }
                
            }
    }else{
        setErrors(errors);
    }
    
    }
  return (
    <div className="login-input-filed-container register-form-container">
    <form autoComplete='off'onSubmit={handleLoginSubmit} >
        <input className="input" type="email" placeholder="Email" value={email}  onChange = {e => setEmail(e.target.value)}  autoComplete="inputfield"/>
        {errors.email && <p className="error"> {errors.email} </p>}

        <div className='login-pw-container'>

          <input type={showPassword ? "text" :"password"}  className="input" placeholder="Password"  value={password} onChange = {e => setPassword(e.target.value)}  autoComplete="new-password"/>

          <div onClick={() => setShowPassword((s) => !s)} style={{ cursor: "pointer" }}>
              {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />} {/* Toggle icon */}
            </div>
        </div>

        {errors.password && <p className="error">{errors.password}</p>}

    <div className="login-forget">
        <span onClick={() => navigate('/forget-password') }>Forget password?</span>
    </div>
    {error && (
        <Alert
          variant={"danger"}
          style={{ display: "flex", alignItems: "center", padding: "10px" }}
        >
          <FaExclamationTriangle
            style={{ marginRight: "10px", color: "#dc3545", fontSize: "20px" }}
          />
          {error}
        </Alert>
      )}
    <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
        <Button
            variant="contained"
            sx={{
                backgroundColor: "#FF3F6C",
                width: "100%",
                borderRadius: "0px",
            }}
type='submit'
            onClick={handleLoginSubmit}
        >
            Login
        </Button>
        
    </div>
    </form>

    <div className="login-no-account">
        <p>
            Don't have an account ?  {" "}
            <span onClick={() => setFormSwitch("register")}>Create one.</span>
        </p>
    </div>
</div>
  )
}

export default LoginForm
