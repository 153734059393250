import React, { useState } from "react";
import OrderSideBar from "../OrderSideBar";
import Profile from "../Profile/Profile";
import PreOrder from "../PreOrder/PreOrder";
import OrdersList from "../OrdersList/OrdersList";
import { handleLogout } from "../../../services/HeaderServices";
import toast from "react-hot-toast";
import AddressDetails from "../AddressBook/AddressList";
import ChangePassword from "../Change Password/ChangePassword";
import Wishlist from "../../Wishlist/Wishlist";
import ContactUs from "../../contact-us/ContactUs";
import useReducerHook from "../../../hooks/useReducerHook";
import { useDispatch } from "react-redux";
import { setActiveSection } from "../../../Redux/Slice/AccountNavigate";
import AddressBook from "../AddressBook/AddressBook";

const Dashboard = () => {
  const token = localStorage.getItem("token");
const dispatch = useDispatch()
  const logout = async () => {
    try {
      const response = await handleLogout(token);
      if (response.status_code === 200) {
        localStorage.removeItem("token");
        toast.success("Logout successfully");
      }
    } catch (error) {
      toast.error("Logout failed");
    }
  };
  const { activeSections } = useReducerHook();
  const renderSection = () => {
    switch (activeSections?.activeSection) {
      case "dashboard":
        return <Profile />;
      case "pre-order":
        return <PreOrder />;
      case "order-details":
        return <OrdersList />;
      case "address-book":
        return <AddressBook />;
      case "change-password":
        return <ChangePassword />;
      case "wishlist":
        return <Wishlist />;
      case "contact-us":
        return <ContactUs />;
      default:
        return <Profile />;
    }
  };

  return (
    <div
      className="container mt-5 vh-100"
      style={{ overflowY: "auto", scrollbarWidth: "thin" }}
    >
      <div className="row h-100">
        <div className="col-12 col-md-4 col-xl-3">
          <div className="card border h-75">
            <div className="mt-4 p-3">
              <div className="text-center">
                <h5 className="mb-2">Profile</h5>
              </div>
              <hr />
              <div className="d-flex flex-column gap-3">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveSection("dashboard"));
                  }}
                  className={`text-decoration-none pointer ${
                    activeSections?.activeSection === "dashboard" ? "active-color" : "text-dark"
                  }`}
                >
                  My Account
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveSection("pre-order"));
                  }}
                  className={`text-decoration-none pointer ${
                    activeSections?.activeSection === "pre-order" ? "active-color" : "text-dark"
                  }`}
                >
                  Preorders
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveSection("order-details"));
                  }}
                  className={`text-decoration-none pointer ${
                    activeSections?.activeSection === "order-details"
                      ? "active-color"
                      : "text-dark"
                  }`}
                >
                  Orders
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveSection("address-book"));
                  }}
                  className={`text-decoration-none pointer ${
                    activeSections?.activeSection === "address-book"
                      ? "active-color"
                      : "text-dark"
                  }`}
                >
                  Address Book
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveSection("change-password"));
                  }}
                  className={`text-decoration-none pointer ${
                    activeSections?.activeSection === "change-password"
                      ? "active-color"
                      : "text-dark"
                  }`}
                >
                  Change Password
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveSection("wishlist"));
                  }}
                  className={`text-decoration-none pointer ${
                    activeSections?.activeSection === "wishlist" ? "active-color" : "text-dark"
                  }`}
                >
                  Wishlist
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveSection("contact-us"));
                  }}
                  className="text-decoration-none text-dark pointer"
                >
                  Contact Us
                </a>
                <a
                  onClick={logout}
                  className="text-decoration-none text-dark pointer"
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 col-xl-9 mt-3 mt-md-0 p-0">
          {renderSection()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
