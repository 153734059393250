import React from "react";
import "./Card.css";

import { useNavigate } from "react-router-dom";
import useFindMenuID from "../../../hooks/useFindSlug";
import { VIEW_PRODUCT_DETAILS } from "../../../shared/slugs";
import useReducerHook from "../../../hooks/useReducerHook";

const Card = ({
  productImg,
  brandName,
  productDes,
  originalPrice,
  DiscountPrice,
  width = 280,
  height = 315,
  productDetails,
  handleViewProduct,
  handleWishlist,
}) => {
  const navigate = useNavigate();
  const {pageMenus}=useReducerHook();
  let viewproductID = useFindMenuID(pageMenus?.menuData, VIEW_PRODUCT_DETAILS);


  const routeToViewCardList = () => {
    navigate(`/${viewproductID?.page_slug}`, {
      state: {
        page_id: viewproductID?.page_id,
        productId: parseInt(productDetails?.product_id),
        text: "Preorder",
      },
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="wrapper dy_product-w-p">
      <div className="product">
        <figure className="product-media">
          <a style={{ cursor: "pointer" }}>
            <img
              src={productImg}
              alt={productDes}
              width={width}
              height={height}
              onClick={routeToViewCardList}
            />
          </a>
        </figure>
        <div className="product-details">
          <div className="product-cat">
            <a href="#"> {brandName} </a>
          </div>
          <h3 className="product-name" onClick={routeToViewCardList}>
            <a style={{ cursor: "pointer" }}>{productDes}</a>
          </h3>
          <div className="product-price">
            <ins className="new-price">Rs. {originalPrice}</ins>
          </div>
          <button
            className="w-100 btn btn-primary action-btn border-0 mt-3 "
            onClick={routeToViewCardList}
          >
            PRE ORDER
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
