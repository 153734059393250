

import { Alert, Button } from '@mui/material';
import '../login/login.css';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { forgetPassword } from '../../services/apiServices';
import ReCAPTCHA from 'react-google-recaptcha';
import useNavigateMenuId from '../../hooks/useMenuId';
import useReducerHook from '../../hooks/useReducerHook';
import { LOGIN } from '../../shared/slugs';
import useFindMenuID from '../../hooks/useFindSlug';
import toast from 'react-hot-toast';

function ForgetPassword() {
  const { navigatePageId } = useNavigateMenuId();
  const { pageMenus } = useReducerHook();
  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [apiError, setApiError] = useState("");
  const recaptchaRef = useRef(null);

  let loginPage = useFindMenuID(pageMenus?.menuData, LOGIN);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleButtonClick = async () => {
    setMessage(null);
    setError(null);

    if (!email.trim()) {
      setError('Email field cannot be empty.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    const recaptchaValue = recaptchaRef.current.getValue();
    if(recaptchaValue){
      let payload ={
        email : email,
        captcha : captcha
      }
      try {
        setLoading(true);
  
        const res = await forgetPassword(payload);
  
        if (res.status_code === 200) {
          setMessage('Temporary password sent successfully. Check your email.');
          setTimeout(() => {
            setLoading(false);
            navigatePageId(loginPage);
  
          }, 1000);
        }
      } catch (err) {
        recaptchaRef.current.reset();
        setCaptcha("")
        setApiError(error?.response?.data?.error)
        setError('An error occurred while sending the password reset link. Please try again.');
      } finally {
        setLoading(false);
      }
     }else{
      toast.error("Please verify captcha")

     }
  
  };

  return (
    <div className="login-container">
      <div className="login-subcontainer">
        <div className="login-banner-img-container">
          <img
            src="https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/2023/1/25/f5e9a029-33c3-4a92-811b-ef7917fe6d441674670210687-offer-banner-300-600x240-code-_-MYNTRA300.jpg"
            alt=""
          />
        </div>

        <div className="login-input-container-field">
          <div className="login-input-filed-container register-form-container">
            <h2 className="form-title">Forget Password</h2>
            {message && (
              <div className="alert alert-success" role="alert">
                {message}
              </div>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <form className="forget-input-container">
              <input
                className="input"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
 <ReCAPTCHA
                    sitekey={siteKey}
                    ref={recaptchaRef}
                    onChange={(value) => setCaptcha(value)}
                />
{apiError &&  <Alert severity="error">{apiError || ""}</Alert>}

              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#FF3F6C',
                  width: '100%',
                  borderRadius: '0px',
                  fontWeight: '600',
                  margin: '20px 0px',
                }}
                onClick={handleButtonClick}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Send Password Reset Link'}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
