import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPageData } from "../../services/SectionService";

export const fetchPageData = createAsyncThunk(
  "page/fetchPageData",
  async (pageId) => {
    try {
      const response = await getPageData(pageId);
      return response;
    } catch (error) {}
  }
);

const initialState = {
  sectionData: [],
  sectionLoading: false,
  sectionError: "",
};

const pageDataSlice = createSlice({
  name: "pageData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageData.pending, (state) => {
        state.sectionLoading = true;
      })
      .addCase(fetchPageData.fulfilled, (state, action) => {
        state.sectionLoading = false;
        state.sectionData = action.payload;
      })
      .addCase(fetchPageData.rejected, (state) => {
        state.sectionLoading = false;
        state.sectionError = "redux errorroroorooror";
      });
  },
});

// export const { setPageData } = pageDataSlice.actions;

export default pageDataSlice.reducer;
