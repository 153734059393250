import React from "react";
import "./extraSection1.css";
import { APP_URL } from "../../../shared/constants";
import useNavigateShop from "../../../hooks/useNavigateShop";
import useReducerHook from "../../../hooks/useReducerHook";
import { SHOP } from "../../../shared/slugs";
import useFindMenuID from "../../../hooks/useFindSlug";
const ExtraSection1 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();

  function consolidatedData() {
    let data = sections?.find((x) => x?.key_name === "ADS")?.ads_data;
    return data;
  }
  const { pageMenus } = useReducerHook();
  let shopPage = useFindMenuID(pageMenus?.menuData, SHOP);
  return (
    <div className="container-fluid my-3">
      <div className="row">
        {consolidatedData()?.length > 0 &&
          consolidatedData()?.map((item) => (
            <div
              className="col-6 col-sm-4 col-md-2 custom-col mt-4"
              key={item?.id}
            >
              <div
                className="extrasex1-img"
                onClick={() => navigateShop(item?.link_one, shopPage)}
              >
                <img
                  src={APP_URL + item?.image_path}
                  alt={item?.name}
                  className="img-fluid d-view"
                />
                 <img
                  src={APP_URL + item?.responsive_image_path}
                  alt={item?.name}
                  className="img-fluid m-view"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExtraSection1;
