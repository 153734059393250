import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getPageData = async (pageId) => {
  try {
    
    const resposne = await apiService.get(`/get-page-data/${pageId}`);
    const data = resposne?.data?.data
    const groupedData = data?.reduce((acc, section) => {
      const { section_name, section_group, place_order } = section;

      if (!acc[place_order]) {
        acc[place_order] = {};
      }

      if (!acc[place_order][section_name]) {
        acc[place_order][section_name] = {};
      }

      if (!acc[place_order][section_name][section_group]) {
        acc[place_order][section_name][section_group] = [];
      }

      acc[place_order][section_name][section_group].push(section);
      return acc;
    }, {});
  
    
    return groupedData
  } catch (error) {}
};
export const getCampaignData = async (camId) => {
  try {
    const response = await apiService.get(`/get-campaign-page/${camId}`);
    return response.data;
  } catch (error) {}
};
export const addToWishlist = async (productId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      product_id: productId,
    };
    const response = await apiService.post("/add-wishlist", payload, config);
    return response.data;
  } catch (error) {
    throw error
  }
};
export const getCampaignList = async () => {
  try {
    const response = await apiService.get("/get-campaign-page/1");
    return response.data;
  } catch (error) {
    throw error;
  }
};