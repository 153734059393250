import React from "react";
import {
  Navigation,
  FreeMode,
  Thumbs,

} from "swiper/modules";
import "swiper/swiper-bundle.css";

import { Swiper, SwiperSlide } from "swiper/react";
import Card from "../../../components/Card";
import { addToWishlist } from "../../../services/SectionService";
import toast from "react-hot-toast";
const Section8 = ({sections}) => {
  const token = localStorage.getItem("token");
  const classificationData = sections?.find((item)=> item.key_name ==="CLASSIFI")?.classification_data?.[0].classifications_data
  const video = sections?.find((item)=> item.key_name ==="Video")?.key_value
  const Appurl = process.env.REACT_APP_IMAGE_URL;
  const handleAddtoCart = async (id) => {
    try {
      const response = await addToWishlist(id, token);
      if (response.status_code === 200) {
        toast.success("Product added to the wishlist");
      }
    } catch (error) {
      if (
        error.response.data.data.error ===
        "token contains an invalid number of segments"
      ) {
        toast.error("Please Login first");
      } else if (
        error.response.data.data.error === "product already exists in wishlist"
      ) {
        toast.error("Product already added in the wishlist");
      }
    }
  };
  return (
    <div className="section10-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4">
            <video
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source
                src={Appurl +video}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="col-lg-8">
            <Swiper
              loop={true}
              spaceBetween={10}
              navigation={false}
              modules={[FreeMode, Navigation, Thumbs]}
              className="quick-modal-swiper2 "
              autoplay={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                
                },
                768: {
                  slidesPerView: 2,
              
                },
                1024: {
                  slidesPerView: 4,
              
                },
              }}
            >
              {classificationData?.map((data, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    height: "auto",
                    cursor: "pointer",
                  }}
                  className="my-2"
                 
                >
                  <Card
                  
                height="280px"
                product={data}
                // handleAddtoCart={()=>handleAddtoCart(+data?.product_id)}
                rating={true}

              />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
