import { configureStore } from "@reduxjs/toolkit";
import userSettingsDataReducer from "./Slice/SettingsDataSlice";
import cartAmountReducer from "./Slice/cartSlice";
import useSearchDataReducer from "./Slice/SearchData";
import userReducer from "./Slice/userDeatails";
import pageDataReducer from "./Slice/SectionSlice";
import useActiveSectionsReducer from './Slice/AccountNavigate';
import pageMenuDatasReducer from "./Slice/PageMenu"
const store = configureStore({
  reducer: {
    userSettingData: userSettingsDataReducer,
    cartAmountDetails: cartAmountReducer,
    pageMenus:pageMenuDatasReducer,
    useSearchData: useSearchDataReducer,
    userDetails: userReducer,
    pageData: pageDataReducer,
    activeSections:useActiveSectionsReducer
  },
});

export default store;
