import React, { useState, useRef, useEffect } from "react";
import './otp.css';
import { useNavigate } from "react-router-dom";
import { checkOtp, verifyOtp as resendOtp} from "../../services/apiServices";
import toast from "react-hot-toast";
import useNavigateMenuId from "../../hooks/useMenuId";
import useReducerHook from "../../hooks/useReducerHook";
import useFindMenuID from "../../hooks/useFindSlug";
import { LOGIN } from "../../shared/slugs";

const Otp = ({ handleCloseOtpModal,setError }) => {

    const navigate =  useNavigate()
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [resendTimer, setResendTimer] = useState(30);
  const [isResendEnabled, setIsResendEnabled] = useState(false); 
  const inputRefs = useRef([]);
  const email = localStorage.getItem("email");
  const { navigatePageId } = useNavigateMenuId();
  const { pageMenus } = useReducerHook();
  let loginPage = useFindMenuID(pageMenus?.menuData, LOGIN);
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendEnabled(true);
    }
  }, [resendTimer]);

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1);
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResendOtp = async () => {
    setIsResendEnabled(false); 
    setResendTimer(30); 
    const payload = {
        email 
    }
    try {
        const res =  await resendOtp(payload);
        console.log(res)        
    } catch (error) {
        
    }
  };
  const checkOtpApi = async (value) => {
    const email = localStorage.getItem("email");
    try {
      const payload = {
        otp_code: value,
        email: email,
      };
      const response = await checkOtp(payload);
      if (response.status_code === 200) {
       handleCloseOtpModal();
       navigatePageId(loginPage);
       setError(null)
        // navigate("/");
        localStorage.removeItem("email");
      } else if (response.status_code === 400){
        toast.error("Given OTP is incorrect")
        // alert("Give the correct otp");
      }
    } catch (error) {}
  };
  const handleSubmit  = () =>{
    if (otp.some((digit) => digit === "")) {
      toast.error("Please fill all OTP Numbers");
      return;
    }
    checkOtpApi(`${otp.join("")}`);


   
  }





  return (
    <div style={{ textAlign: "center" }} className="otp-container">
        <button onClick={handleCloseOtpModal} className="otp-close-btn">X</button>
      <h2 style={{ fontSize: "26px" }}>Enter OTP</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputRefs.current[index] = el)}
            style={{
              margin: "10px 0px",
              width: "40px",
              height: "40px",
              fontSize: "18px",
              textAlign: "center",
              border: "1px solid #ccc",
            }}
          />
        ))}
      </div>

      <div className="resend-otp-container">
        {isResendEnabled ? (
          <span onClick={handleResendOtp} >
            Resend OTP
          </span>
        ) : (
          <span style={{color:'grey'}}>Resend OTP in<span> (00:{resendTimer})</span> s</span>
        )}
      </div>

      <button
        onClick={handleSubmit}
        className="otp-btn"
      >
        Verify OTP
      </button>
    </div>
  );
};

export default Otp;
