import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeSection: "dashboard",
};
const useActiveSections = createSlice({
  name: "useActiveSections",
  initialState,
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
  },
});

export const { setActiveSection } = useActiveSections.actions;
export default useActiveSections.reducer;
