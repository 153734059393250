import { RiDeleteBin6Line } from "react-icons/ri";
import "./cart.css";
import { RxCross2 } from "react-icons/rx";
import {
  getUserCartTotalDetailsApi,
  removeCartApi,
  updateCartApi,
  userCartList,
} from "../../../services/CartService";
import { useEffect, useState } from "react";
import { APP_URL } from "../../../shared/constants";
import Counter from "../../../components/Counter/Counter";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { fetchUserData } from "../../../Redux/Slice/userDeatails";
import { fetchCartAmountDetials } from "../../../Redux/Slice/cartSlice";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { getPreOrderList } from "../../../services/orderServices";
import { SHOP } from "../../../shared/slugs";
import useFindMenuID from "../../../hooks/useFindSlug";
import useReducerHook from "../../../hooks/useReducerHook";
import useNavigateMenuId from "../../../hooks/useMenuId";

function PreOrder() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [cartListData, setCartListData] = useState([{}]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [loadingIds, setLoadingIds] = useState([]);
  const [isConfirmDeleteCart, setIsConfirmDeleteCart] = useState(false);
  const [productID, setProductID] = useState(null);
  const location = useLocation();
  const {navigatePageId}= useNavigateMenuId();
  const { pageMenus } = useReducerHook();

  let shopPage = useFindMenuID(pageMenus?.menuData, SHOP);
  useEffect(() => {
    fetchCartList();
  }, []);

  const fetchCartList = async () => {
    setIsLoading(true);
    try {
      const response = await getPreOrderList(token);
      if (response.data) {
        setCartListData(response?.data);
      }
    } catch (error) {
      console.error("Failed to fetch wishlist:", error);
      
    } finally {
      setIsLoading(false);
    }
  };
  // const cartRemove = async (id) => {
  //   setLoadingIds((prev) => [...prev, id]);
  //   try {
  //     let payload = {
  //       product_id: id,
  //     };
  //     const response = await removeCartApi(token, payload);
  //     if (response?.status_code === 200) {
  //       setCartListData((prev) =>
  //         prev?.filter((item) => item.product_id !== id)
  //       );
  //       dispatch(fetchUserData(token));
  //       // dispatch(fetchUserCartDetails(token));
  //       dispatch(fetchCartAmountDetials(token));
  //       toast.success("Cart deleted Successfully");
  //       setIsConfirmDeleteCart(false);
  //       // getUserCartTotalCount();
  //     }
  //   } catch (error) {
  //     console.error("Failed to remove item from wishlist:", error);
  //     setIsConfirmDeleteCart(false);
  //   } finally {
  //     setLoadingIds((prev) => prev.filter((itemId) => itemId !== id));
  //     setIsConfirmDeleteCart(false);
  //   }
  // };
  // const updateCart = async (data, count) => {
  //   let payload = {
  //     product_id: data?.product_id,
  //     quantity: count,
  //   };

  //   const addToCartResponse = await updateCartApi(token, payload);
  //   if (addToCartResponse.status_code === 200) {
  //     toast.success(addToCartResponse?.message);
  //     fetchCartList();
  //     dispatch(fetchCartAmountDetials(token));
  //   } else if (addToCartResponse === 403) {
  //     toast.error("Error");
  //   } else {
  //     toast.error(" Please login.");
  //   }
  // };

  return (
    <div className="container my-5">
      {cartListData?.length === 0 ? (
        <div className=" d-flex justify-content-center">
          <div>
            <h5>No preorders Avaiable</h5>
            <button
              onClick={() => navigatePageId(shopPage)}
              style={{
                color: "white",
                marginTop: "15px",
                border: "none",
                padding: "10px",
              }}
              className="btn btn-primary"
            >
              Continue Shopping
            </button>
          </div>
        </div>
      ) : (
        <>
          {cartListData?.length > 0 && 
          <div className="cart-container">
            <div className="cart-desktop">
            
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    cartListData?.map((el) => (
                      <tr key={el?.product_id}>
                        <td className="cart-pro-img pointer" >
                          <img src={APP_URL + el?.thumnail_image} alt="" />
                          <p>{el?.product_name}</p>
                        </td>
                        <td>
                          {el?.discount_price === 0 ? (
                            <span>
                              Rs.
                              {el?.original_price ? parseFloat(el?.original_price).toFixed(2) : ""}
                            </span>
                          ) : (
                            <span>
                             
                              {el?.discount_price ?  ` Rs.${parseFloat(el?.discount_price).toFixed(2)}` :""}
                            </span>
                          )}
                        </td>
                        <td className="cart-quantity">
                          {el?.item_count || "" }
                          {/* <div> */}
                          {/* <Counter
                            initialValue={el?.item_count || 1}
                            minValue={1}
                            onChange={(value) => {
                              setQuantity(value);
                              updateCart(el, value);
                            }}
                          /> */}
                          {/* </div> */}
                        </td>
                        <td className="cart-delete">
                          <div>
                            <span> {el?.sub_total ? `Rs.${el?.sub_total?.toFixed(2)}`:""}</span>
                            {/* {loadingIds?.includes(el?.product_id) ? (
                              <ColorRing
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={["#849b87"]}
                              />
                            ) : (
                              <span
                                style={{ cursor: "pointer" }}
                                // onClick={() => cartRemove(el?.product_id)}
                                onClick={() => {
                                  setProductID(el?.product_id);
                                  setIsConfirmDeleteCart(true);
                                }}
                              >
                                <RiDeleteBin6Line />
                              </span>
                            )} */}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="cart-mobile">
              {cartListData?.map((el) => (
                <div key={el?.product_id} className="cart-card">
                  <div className="cart-card-img">
                    <img src={APP_URL + el?.thumnail_image} alt="" />
                  </div>
                  <div className="cart-card-details">
                    <p>{el?.product_name}</p>

                    <div className="mobile-cart-price">
                      {el?.discount_price === 0 ? (
                        <span>
                          Rs.
                          {parseFloat(el?.original_price).toFixed(2) || ""}
                        </span>
                      ) : (
                        <span>
                          Rs.
                          {parseFloat(el?.discount_price).toFixed(2) || ""}
                        </span>
                      )}
                    </div>
                    <div className="cart-quantity">
                      {el?.item_count }
                      {/* <Counter
                        initialValue={el?.item_count || 1}
                        minValue={1}
                        onChange={(value) => {
                          setQuantity(value);
                          updateCart(el, value);
                        }}
                      /> */}
                    </div>
                  </div>

                  <div className="mobile-cart-close">
                    {/* {loadingIds?.includes(el?.product_id) ? (
                      <ColorRing
                        visible={true}
                        height="30"
                        width="30"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["#849b87"]}
                      />
                    ) : (
                      <span
                        style={{ cursor: "pointer" }}
                        // onClick={() => cartRemove(el?.product_id)}
                        onClick={() => {
                          setProductID(el?.product_id);

                          setIsConfirmDeleteCart(true);
                        }}
                      >
                        <RiDeleteBin6Line />
                      </span>
                    )} */}
                  </div>
                </div>
              ))}
            </div>
          </div>}
          {/* {location.pathname !== "/checkout" && (
            <button
              className="btn btn-primary float-end mt-1"
              onClick={() => navigate("/checkout/cart")}
            >
              Proceed to Checkout
            </button>
          )} */}
        </>
      )}
      {/* {isConfirmDeleteCart && (
        <ConfirmationDialog
          onClose={() => setIsConfirmDeleteCart(false)}
          handleDelete={() => cartRemove(productID)}
          primaryText={"Want to delete the item?"}
          actiontext={"Yes,delete it!"}
        />
      )} */}
    </div>
  );
}

export default PreOrder;
