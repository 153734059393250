import React, { useEffect, useState } from "react";
import { getCampaignList } from "../../services/SectionService";
import Card from "../../components/Card";


const Campaign = ({ sections }) => {
  const [campaign, setCampaign] = useState([]);
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    getAllProducts();
  }, []);
  const getAllProducts = async () => {
    try {
      const response = await getCampaignList();
      const status = await response.status_code;
      if (status === 200) {
        const responseData = [
          {
            campaign_data: [
              {
                campaign_name: response.data.campaign_name,
                expire_date: response.data.expire_date,
                title: response.data.title,
                description: response.data.description,
                image_path: response.data.image_path,
              },
            ],
            key_name: "CAMP",
          },
        ];
        setBannerData(responseData);

        setCampaign(response.data.products_data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {bannerData?.length > 0 && (
        <>
          <div
            className="text-center mb-5 mt-2"
            style={{ fontSize: "28px", fontWeight: "bold", color: "" }}
          >
            Campaign List
          </div>
          <section className="section_campaign">
            <div className="container">
              <div className="row ">
                {campaign?.map((x) => (
                  <div className="col-md-3 " key={x.id}>
                    <Card
                      height="350px"
                      product={x}
                      // handleAddtoCart={()=>handleAddtoCart(+item?.product_id)}
                      // item={item}
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default Campaign;
